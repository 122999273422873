import Pagination from '@mui/material/Pagination';
import { graphql, navigate } from 'gatsby';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { getCLS, getFID, getLCP } from 'web-vitals';
import { EN_CODE } from '../common/Constants';
import Layout from '../components/Layout/Layout';
import Post from '../components/Post/Post';
import Sidebar from '../components/Sidebar/Sidebar';
import icon32 from './favicon.jpg';

const IndexRoute = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || 'Title';
  const posts = data.allMarkdownRemark.edges;

  useEffect(() => {
    getCLS(console.log);
    getFID(console.log);
    getLCP(console.log);
  }, []);

  const gotoPage = (evt, page) => {
    if (page === 1) {
      navigate('/');
    } else {
      navigate(`/en/page/${page}`);
    }
  };

  const items = posts.map((post) => (
    <Post
      data={post}
      key={post.node.fields.slug}
      lang={post.node.frontmatter.lang}
    />
  ));

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet>
        <title>{siteTitle}</title>
        <script defer data-domain="tderflinger.com" src="https://plausible.io/js/script.js"></script>
        <meta
          name="description"
          content="My name is Thomas Derflinger, I'm a freelance web programmer, and this is my blog. I write about a lot of things, but mostly web programming, DevOps, VR, and IoT."
        />
        <link rel="shortcut icon" type="image/jpeg" href={icon32} />
      </Helmet>
      <Sidebar data={data} lang={EN_CODE} />
      <div className="content">
        <div className="mt-10 mx-6 mb-4">{items}</div>
        <Pagination
          count={6}
          color="primary"
          className="mb-8"
          onChange={gotoPage}
        />
      </div>
    </Layout>
  );
};

export default IndexRoute;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        url
        author {
          name
          twitter
          github
          rss
        }
      }
    }
    allMarkdownRemark(
      limit: 8
      filter: {
        frontmatter: {
          layout: { eq: "post" }
          draft: { ne: true }
          lang: { eq: "en" }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            lang
            path
            pathEn
            license
            date
            category
            description
            attachments {
              childImageSharp {
                gatsbyImageData(
                  width: 150
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
